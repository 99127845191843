<template>
  <div v-loading="loading" id="page"></div>
</template>
<script>
export default {
  name: "ERP-PreviewPDF",
  data() {
    return {
      loading:true
    };
  },
  created() {
    let itemCode = this.$route.query.itemCode;
    let productListType = this.$route.query.type;
    let params = {
      itemCode: itemCode,
      productListType: productListType,
    };
    this.loadData(params);
  },
  mounted() {},
  methods: {
    loadData(params) {
      this.$api.handleSpec.getNewSpecAuditedLatestInfo(params).then((res) => {
        this.previewHandle(res);
        
      });
    },
    previewHandle(data) {
      data.json_value=data.jsonValue;
      let params = {
        language: "US",
        data: data,
        cutomer_signature: false,
        preview_pdf: "",
        file_name: "erp_" + data.itemCode, //item_code+version+signature
        response_type: "text",
      };

      this.$axios
        .post("https://spec.dracoherbs.com/flask/generate_spec", params, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          let fileUrl =
            "https://spec.dracoherbs.com/download/" + response.data.file_name;
          let encodeUrl = encodeURIComponent(fileUrl);
          let url = "https://ofd.xdocin.com/view?src=" + encodeUrl;
          console.info("url:", url);
          setTimeout(function () {
            this.previewPdfLoading = true;
            // window.open(url);
            location.replace(url);
          }, 200);
        })
        .catch((error) => {
          this.previewPdfLoading = true;
          console.error(error);
        });
    },
  },
};
</script>

<style>
#page{
  height: 100vh;
}
</style>